<template>
  <div class="pin-gen-main container">
    <!-- <div class="title">
      <div class="box-series" :dir="localDisplayLang === 'ara' ? 'rtl' : 'ltr'">
        <img
          src="@/assets/icons/back.svg"
          v-if="localDisplayLang === 'eng'"
          alt
          @click="initialRoute"
        />
        <img
          src="@/assets/icons/back.svg"
          class="rotate-img"
          v-else
          @click="initialRoute"
          alt
        />
        &nbsp;&nbsp;&nbsp;
        <p>{{$t('Pin Generator')}}</p>
      </div>
    </div> -->
    <div class="pin-gen-body">
      <div class="title-pin">
       {{$t('Description tv text')}} 
      </div>
			<div class="title-pin-new">
       {{$t('Description tv text2')}} 
      </div>
      <div class="pin-gen-body-input">
        <!-- <input
          type="text"
          maxlength="6"
          class="lookup-form-container-input-container-inputtext"
        /> -->
        <div class="pin-gen-text">{{pinGenText}}</div>
          <Loading v-if="statusCode"></Loading>
        <button
          type="button"
          title="GENERATE PAIRING KEY"
          @click="actgenpin"
          class="button-primary login primary-net5-button key-gen-button">
          <span class="btn-txt-color">{{$t('GENERATE PAIRING KEY')}}</span>
        </button>

        <p class="error-text" v-if="errorTxt">{{errorTxt}}</p>
        <p>{{$t('Support tv Text')}}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { eventBus } from "@/eventBus";
import { store } from "@/store/store";

export default {
  data() {
    return {
      localDisplayLang: null,
      pinGenText: '',
      errorTxt: '',
      statusCode: false
    };
  },
  methods: {
    initialRoute() {
      this.$router.push({ name: "Home" });
    },
    debounce(func, timeout = 300){
      let timer;
      return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => { func.apply(this, args); }, timeout);
      };
    },
    actgenpin () {
      let isAnonymous = localStorage.getItem("subscriberDetails") ? false : true;
      if ( isAnonymous ) {
         let payload = {
            state: true,
            formType: "lookup"
            };
            eventBus.$emit("authPopup", payload);
  
      } else {
        this.statusCode = true;
        console.log("pin generation", this.pinGenText)
          let payload = {
              devicetype: 'TV'
          }
          store.dispatch("actGetTvPin", payload).then(response => {
            this.statusCode = false;

            if (!response.data.errorcode) {
              this.errorTxt = ''
              this.pinGenText = response.data.devicepin;
            } else if (response.data.errorcode) {
              this.errorTxt = response.data.reason
            }
          }).catch(() => { 
            this.statusCode = false;
            this.errorTxt = "please try again";
          })
      }
    }
  },
  mounted() {
    this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");

    this.actgenpin = this.debounce(this.actgenpin, 500)

    eventBus.$on("check-local-storage", (response) => {
      this.localDisplayLang = response;
    });
  },
  components: {
    Loading: () =>
      import(
        /* webpackChunkName: "Loading" */ "@/components/Templates/Loading.vue"
      )
  }
};
</script>

<style lang="scss" scoped>

@import "@/sass/_variables.scss";
.pin-gen-main {
  color: $clr-light-gd3;
}
.pin-gen-body {
  font-family: $font-regular;
  .title-pin {
    text-align: center;
    margin: 40px 0px 17px;
    color:  white;
    opacity: 0.7;
    font-size: 0.8rem;
  }
	.title-pin-new {
    text-align: center;
   // margin: 40px 0px;
    color:  white;
    opacity: 0.7;
    font-size: 0.8rem;
  }
  .error-text {
    text-align: center;
    margin: 15px 0px;
    color: $btn-clr !important;
    font-size: 0.8rem;
  }
  .pin-gen-body-input {
    width: 50%;
    margin: 2rem auto;
    text-align: center;
    input[type="text"] {
      font-weight: bold;
      font-size: 4vw;
      letter-spacing: 2vw;
      border: 0.5px solid #212121;
      text-align: center;
    }
    .pin-gen-text {
      font-weight: bold;
      font-size: 4vw;
      letter-spacing: 1vw;
      color: white;
    }
    .key-gen-button {
      margin: 2rem auto;
    }
    p {
      text-align: center;
      color:  white;
      opacity: 0.7;
    }
  }
}

.title {
  .box-series {
    display: flex;
    img {
      cursor: pointer;
      width: 1rem;
      opacity: 0.5;
      &:hover {
        opacity: 1;
      }
    }
    .rotate-img {
      transform: rotate(180deg);
    }
    p {
      opacity: 1;
      color: $clr-light-gd3;
      font-family: $font-regular;
      font-size: 0.8rem;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 1.5rem;
    }
  }
}
</style>

<style>
 @media only screen and (max-width: 576px) {
  .pin-gen-text {
        font-size: 11vw !important;
    }
    .pin-gen-body-input {
        width: 100% !important;
    }
  }
</style>