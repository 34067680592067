<template>
  <div class="wrapper">
    <div
      v-if="!success && subscriberid"
      class="container loginControls"
      :class="{
        'container-ara': seasonUiFlip === 'ara' ? true : false,
        'container-default': seasonUiFlip !== 'ara' ? true : false,
      }"
    >
      <div class="logo" :class="localDisplayLang === 'ara' ? 'mr' : ''">
        <img
          src="@/assets/icons/pin-pairing-logo.svg"
          alt="noor_play_logo"
          class="noorPlay_logo"
        />
        <img
          src="@/assets/icons/pin-mobile.svg"
          alt="noor_play_logo"
          class="noorPlay_logo_pin"
        />
      </div>
      <!-- <h1>Enter the pin Generated on your TV</h1> -->
      <h1>{{ $t("enter-pin") }}</h1>

      <div class="pin-table-form">
        <div class="pin-table">
          <div class="pin">
            <div id="pininputs" class="profile-popup-pin-box-inputs">
              <input
                v-for="(item, index) in 6"
                :key="index"
                class="pin"
                type="tel"
                v-model="userPin[item - 1]"
                @input="grabPin"
                maxlength="1"
              />
            </div>
          </div>
        </div>

        <h2 class="validation-message" v-if="invalidPin">
          {{ $t(errorMessage) }}
        </h2>

        <h2 class="validation-message" v-if="loadingState">
          {{ $t("loading") }}...
        </h2>
      </div>
      <div class="container-1">
        <button
          id="button1"
          class="loginWithEmail button-primary primary-net5-button"
          v-on:click="pinVerify"
        >
          <span class="btn-txt-color">{{ $t("continue") }}</span>
        </button>
      </div>
      <div class="about-description">
        <p class="about-description-paragraph">
          {{ $t("older-approach-text") }}
          <br />
					
          <!-- <a href="https://noorplay-web-test.web.app/tv" onclick="check()">{{
            $t("click-here")
          }}</a> -->
        </p>
        <p class="click-here" @click="clickMethod()">{{$t("click-here")}}</p>
      </div>
    </div>
    <transition>
      <AuthPopup
        v-if="!subscriberid"
        :formInfo="authInfo"
        :closePopup="() => closeAuthPopup(false)"
      />
    </transition>
    <SuccessModal
      v-if="success"
      id="pc-pin-modal"
      :open="showPinModal"
      @onClose="closePinModal"
      @onActionComplete="onPinModalActionComplete"
    />
  </div>
</template>

<script>
import Utility from "@/mixins/Utility.js";
import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
  components: {
    SuccessModal: () => import("@/components/utils/SuccessModal.vue"),
    AuthPopup: () =>
      import(
        /* webpackChunkName: "header" */ "@/components/Popups/Authentication/userAuth.vue"
      ),
  },
  mixins: [Utility],
  name: "LoginWithPinView",

  data() {
    return {
      input: {
        code: [],
      },
      currentIndex: 0,
      enteredPinCode: null,
      invalidPin: false,
      resData: null,
      errorMessage: "",
      loadingState: false,
      configClass: null,
      displayLang: null,
      showModal: false,
      countryName: null,
      amount: 6,
      userPin: [],
      success: false,
      profilePin: "",
      authInfo: {},
    };
  },

  computed: {
    ...mapGetters(["subscriberid", "getPairingFlag"]),
  },
  mounted() {
    this.setPairingFlag(true);
    this.authInfo.formType = "lookup";
    this.setupPin();
  },

  directives: {
    focus: {
      componentUpdated: function(el, obj) {
        obj.value && el.focus();
      },
    },
  },

  created() {
    this.code = new Array(this.amount).fill("");
  },

  methods: {
    ...mapMutations(["setPairingFlag"]),
    ...mapActions(["pairDevice"]),
    setupPin() {
      setTimeout(() => {
        this.getId = document.getElementById("pininputs");
        this.pinFunctionality(this.getId);
      }, 1000);
    },
		clickMethod(){
			this.$router.push({ path: "/tv" });
		},
    closeAuthPopup(state) {
      this.$router.replace({
        name: "Home",
        params: { lang: this.$i18n.locale == "ara" ? "ar" : "en" },
      });
      // this.$router.push({ name: "Home" });
    },
    setupPin() {
      setTimeout(() => {
        this.getId = document.getElementById("pininputs");
        if (this.getId) {
          this.pinFunctionality(this.getId);
        }
      }, 1000);
    },

    // Fetch the pins as the user user enter in the input.
    grabPin() {
      this.errorMessage = "";
      if (this.userPin.length > 3) {
        let pin = "";
        this.userPin.forEach((element) => {
          pin += element;
        });
        this.profilePin = pin;
      }
    },
    pinVerify() {
      if (!this.profilePin) {
        this.errorMessage = "Please enter the Pin";
        this.invalidPin = true;
        return;
      }
      let inputEntered = this.input.code.join("");
      const profilePayload = {
        pin: this.profilePin,
      };

      this.pairDevice(profilePayload)
        .then((response) => {
          if (!response.data.reason) {
            this.success = true;
            this.showPinModal = true;
          } else {
            // this.success = true;
            // this.showPinModal = true;
            this.errorMessage = response.data.reason;
            this.invalidPin = true;

            this.userPin = [];
            this.profilePin = "";

            //showSnackBar(this.$t(response.data.reason));
          }
        })
        .catch((err) => {
          this.isLoading = false;
        });
    },
    onDelete(e, index) {
      if (e.target.value === "") {
        this.currentIndex = index - 1;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.about-description-paragraph {
	padding: 1rem !important;
}
.click-here {
	line-height: 0px;
	color: #ec1c65 !important;
}
.noorPlay_logo_pin {
  padding: 3px;
}
.about {
  border-radius: 10px;
  padding: 4rem;
  display: grid;
  justify-content: center;
  &-description {
    &-paragraph {
      text-align: center;
      font-size: 1.2rem;
      letter-spacing: 0;
      line-height: 2.4rem;
      padding: 2rem;
      line-height: 25px;
    }
  }
}
.pin-texts {
  width: 20px;
}
a {
  font-size: 14px;
  color: #ec1c65;
}
.pin-text {
  color: #ffffff;
}
.validation-message {
  font-family: "Open Sans", sans-serif, DroidArabicKufiRegular;
  margin: 25px 672px 4px -15px;

  color: #fff;
}
.pin-table-form {
  left: 34%;
  position: relative;
}
.profile-popup-pin-box-inputs {
  align-items: center;
  display: flex;
  width: 33%;
}
.container h1 {
  color: white;
  font-size: 1rem;
  font-family: "Open Sans", sans-serif, DroidArabicKufiRegular;
}

//LSF
.container-1 {
  display: flex;
  justify-content: space-around;
}
button {
  width: 32%;
  white-space: nowrap;
  text-align: center;
  margin: 5px; /* or whatever you like */
}
.loginWithEmail {
  background-color: #1a1a1a;
  font-size: 17px;
  font-weight: bold;
  padding: 13px 15px;
  cursor: pointer;
  text-align: center;
  //width: 60%;
  outline: none;
  //border: none;
  border-radius: 10px;
}
.wrapper {
  position: relative;
  height: 100vh;
}

.container {
  text-align: center;
  height: 100vhpx;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 1;
  &.container-ara {
    width: 1345px;
  }
  &.container-default {
    width: 987px;
    display: grid;
    justify-content: center;
    gap: 30px;
  }
  h1 {
    font-weight: bold;
    font-size: 24px;
  }
  p {
    font-family: "Open Sans", sans-serif, DroidArabicKufiRegular;
    font-size: 14px;
    color: #ffffff;
  }
}

.pin-table {
  .pin {
    display: inline-flex;
    text-align: center;
    vertical-align: middle;
    margin-right: 5px;

    .pinBlocks {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      margin-top: 25px;

      .pin-block {
        padding: 10px 10px;
        width: 92px;
        height: 124px;
        margin: 0px 5px;
        text-align: center;
        font-size: 2em;
        font-weight: bold;
        // color: $text-noorplay-primary-color;
        background-color: #ffffff1a;
        outline: none;
        border: none;
        border-radius: 10px;
        &:focus::placeholder {
          color: transparent;
        }
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  .container h1 {
    font-size: 14px;
  }
  .loginWithEmail {
    font-size: 13px;
  }
  .validation-message {
    margin: 10px 667px 3px 26px;

    //margin: 10px 548px 3px 0px;
    font-size: 14px;
    font-family: "Open Sans", sans-serif, DroidArabicKufiRegular;
  }
}
@media only screen and (min-width: 2200px) {
  .container {
    text-align: center;
    width: 1974px;
    height: 1722px;

    h1 {
      font-weight: bold;
      font-size: 124px;
      color: white;
    }
    p {
      font-weight: bold;
      margin-top: 68px;
    }
  }

  .pin-table {
    .pin {
      .pinBlocks {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-top: 50px;

        .pin-block {
          padding: 20px;
          width: 184px;
          height: 284px;
          margin: 0px 10px;
          text-align: center;
          // font-size: 64px;
          border-radius: 20px;
        }
      }
    }
  }
}
</style>
